@import "#styles/mixins.scss";
@import "#styles/variables.scss";

.news {
  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__banner {
    > img,
    video {
      display: block;
      max-width: 100%;
      max-height: 55vh;
      margin: 0 auto 10px;
    }

    &__title {
      font-size: 30px;
    }
  }

  &__tabs {
    margin-bottom: 20px;

    @include respond(m) {
      display: flex;
      justify-content: center;
    }
  }

  &__list {
    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
