@import "#styles/mixins.scss";
@import "#styles/variables.scss";

$vertical-padding: 0;

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: $color-black-1;
  width: 100%;
  height: $header-height;
  padding: $vertical-padding calc((100vw - $max-width) / 2);
  align-items: center;
  z-index: 99;

  @include respond(xl) {
    padding: $vertical-padding $section-padding-xl;
  }

  @include respond(l) {
    height: $header-height-l;
  }

  @include respond(m) {
    padding: $vertical-padding $section-padding-m;
  }

  @include respond(s) {
    height: $header-height-s;
    padding: $vertical-padding $section-padding-s;
  }

  &__logo {
    position: relative;
    margin-right: auto;

    @include respond(l) {
      margin-left: auto;
      order: 2;
    }

    > h1 {
      position: absolute;
      top: -1000%;
      left: -1000%;
    }

    > img {
      width: 180px;
      height: 55px;
      vertical-align: bottom;

      @include respond(l) {
        width: 144px;
        height: 44px;
      }

      @include respond(s) {
        width: 126px;
        height: 38.5px;
      }
    }
  }

  &__toggle {
    display: none;
    width: 40px;
    height: 40px;

    @include respond(l) {
      display: flex;
      justify-content: center;
      align-items: center;
      order: 3;
    }

    &__line {
      background-color: $color-red-1;
      position: relative;
      width: 80%;
      height: 2px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: inherit;
        right: 0;
        height: 100%;
      }

      &::before {
        top: -12px;
        width: 100%;
      }

      &::after {
        top: 12px;
        width: 60%;
      }
    }
  }

  &__nav {
    margin-right: 20px;

    @include respond(l) {
      position: absolute;
      top: $header-height;
      right: -100%;
      background-color: rgba($color-black-1, 0.95);
      width: 100%;
      margin-right: 0;
      transition: right 0.3s;

      &.showNav {
        right: 0;
      }
    }

    @include respond(l) {
      top: $header-height-l;
    }

    @include respond(s) {
      top: $header-height-s;
    }

    > ul {
      display: flex;
      align-items: center;

      @include respond(l) {
        flex-direction: column;
      }

      > li {
        margin: 0;
        padding: 0;
        @include respond(l) {
          width: 100%;
        }
      }
    }

    &__link {
      position: relative;
      display: flex;
      padding: 32px 20px;
      justify-content: center;
      cursor: pointer;

      @include respond(l) {
        padding: 20px;
      }

      &::after {
        content: "";
        background-color: $color-red-1;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        transition: width 0.2s;

        @include respond(l) {
          bottom: 10px;
        }
      }

      &:hover,
      &.current {
        &::after {
          width: 85%;

          @include respond(l) {
            width: 8em;
          }
        }
      }

      &--contact {
        max-height: 100%;
        padding: 20px;
        background-color: $color-red-1;
        flex-direction: column;
        align-items: center;
        transition: all 0.2s;

        @include respond(l) {
          padding: 15px;
        }

        &::after {
          content: none;
        }

        &:hover {
          background-color: rgba($color-red-1, 0.9);
        }
      }
    }
    &__link__nest {
      position: relative;
      display: flex;
      padding: 15px 10px;
      justify-content: center;
      cursor: pointer;

      &__collapse {
        position: relative;
        left: 10px;
        transform: rotate(-90deg);
        font-size: 1em;
        // transition: 0.3s;

        &.open {
          transform: rotate(-270deg);
        }
      }

      @include respond(l) {
        padding: 20px;
      }

      &::after {
        content: "";
        background-color: $color-red-1;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        transition: width 0.2s;

        @include respond(l) {
          bottom: 10px;
        }
      }

      &:hover,
      &.current {
        &::after {
          width: 100%;

          @include respond(l) {
            width: 8em;
          }
        }
      }
    }
  }
}
