@import "#styles/mixins.scss";
@import "#styles/variables.scss";
@import "#styles/extends.scss";

.gameDetail {
  position: relative;

  &__back {
    position: relative;
    top: $section-vertical-padding;
    left: calc((100vw - $max-width) / 2);
    display: flex;
    align-items: center;
    column-gap: 5px;
    opacity: 0.8;
    transition: all 0.2s;

    &:hover {
      column-gap: 8px;
      opacity: 1;
    }

    &__icon {
      font-size: 2em !important;

      > path {
        color: $color-red-1;
      }
    }

    @include respond(xl) {
      left: $section-padding-xl;
    }

    @include respond(m) {
      position: static;
      display: inline-flex;
      vertical-align: top;
      padding: $section-vertical-padding $section-padding-m 0;
    }

    @include respond(s) {
      padding: $section-vertical-padding-s $section-padding-s 0;
    }
  }

  &__section {
    display: flex;

    &--leftRight {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include respond(m) {
        flex-direction: column-reverse;
        row-gap: 20px;
      }

      > * {
        width: 49%;

        @include respond(m) {
          width: auto;
        }
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    &__markdown {
      @extend %markdown;
    }
  }

  &__image {
    display: flex;
    justify-content: center;

    > img {
      width: 100%;
      max-width: 80%;

      @include respond(l) {
        max-width: none;
      }
    }
  }

  &__button {
    margin-top: 30px !important;
    align-self: center;

    @include respond(s) {
      margin-top: 20px !important;
    }

    > a {
      display: flex;
      width: 100%;
      padding: 15px 40px;
      font-size: 22px;
      justify-content: center;
    }
  }

  &__data {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px 100px;

    @include respond(s) {
      gap: 15px;
    }

    &__item {
      min-width: 150px;

      @include respond(m) {
        min-width: 130px;
      }
    }

    &__value {
      margin-bottom: 10px;
      font-size: 30px;

      @include respond(s) {
        font-size: 24px;
      }
    }

    &__label {
      font-size: 20px;
      opacity: 0.6;

      @include respond(s) {
        font-size: 18px;
      }
    }
  }

  &__gallery {
    display: flex;
    justify-content: center;

    @include respond(m) {
      > img {
        max-width: 100%;
      }
    }
  }

  &__table {
    tr > td {
      &:first-child {
        width: 35%;

        @include respond(s) {
          width: 40%;
          padding-right: 0;
        }
      }
    }
  }
}
