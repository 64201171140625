@mixin respond($breakpoint) {
  @if $breakpoint == s {
    @media (max-width: 375px) {
      @content;
    }
  }

  @if $breakpoint == m {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == l {
    @media (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media (max-width: 1600px) {
      @content;
    }
  }
}
