$max-width: 1440px;
$section-vertical-padding: 50px;
$section-vertical-padding-s: 20px;
$section-padding-xl: 60px;
$section-padding-m: 30px;
$section-padding-s: 20px;
$header-height: 88px;
$header-height-l: 70px;
$header-height-s: 60px;

$color-black-1: #202020;
$color-black-2: #2c2d2d;
$color-white-1: #fefefe;
$color-red-1: #d51427;
$color-blue-1: #1d2dde;

$img-border-radius: 15px;
