@import "#styles/variables.scss";

$scale-total: 5;
$scale-height: 35px;
$scale-base: 5px;

.scale {
  display: flex;
  align-items: flex-end;

  &__item {
    width: 10px;
    height: $scale-height;
    border: 2.5px solid $color-white-1;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &.fill {
      background-color: $color-white-1;
    }

    @for $i from 1 through $scale-total {
      &:nth-child(#{$i}) {
        height: calc($scale-height * $i / $scale-total + $scale-base);
      }
    }
  }
}
