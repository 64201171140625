@import "#styles/mixins.scss";
@import "#styles/variables.scss";

$label-width: 100px;
$label-width-s: 80px;
$label-margin-right: 15px;

.contact {
  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: 1300px;
    margin-top: 40px;
    gap: 0 100px;

    @include respond(l) {
      flex-direction: column;
      gap: 20px 0;
    }

    > * {
      flex: 1;
      text-align: center;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &__submit {
      margin-left: calc($label-width + $label-margin-right) !important;
      padding: 8px 50px !important;
      align-self: flex-start;

      @include respond(l) {
        align-self: flex-end;
      }
    }
  }

  &__info {
    @include respond(l) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &__title {
      font-size: 30px;
      margin-bottom: 10px;

      @include respond(l) {
        text-align: center;
      }
    }

    &__content {
      @include respond(l) {
        text-align: center;
      }
    }
  }
}
