@import "#styles/mixins.scss";
@import "#styles/variables.scss";

$vertical-padding: 30px;

.footer {
  display: flex;
  padding: $vertical-padding calc((100vw - $max-width) / 2);
  justify-content: space-between;
  gap: 20px 40px;

  @include respond(xl) {
    padding: $vertical-padding $section-padding-xl;
  }

  @include respond(l) {
    flex-direction: column;
  }

  @include respond(m) {
    padding: $vertical-padding $section-padding-m;
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    > li {
      width: 40px;
      height: 40px;
      transition: all 0.1s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__title {
      font-weight: bold;

      @include respond(s) {
        font-size: 14px;
      }
    }

    &__content {
      font-size: 14px;

      @include respond(s) {
        font-size: 12px;
      }
    }
  }
}
