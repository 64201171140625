@import "#styles/mixins.scss";
@import "#styles/variables.scss";

.ai_gc {
  &__section {
  }
  &__item {
    display: flex;
    width: 100%;
    padding: 60px;
    border-bottom: 1px solid rgba($color: #fefefe, $alpha: 0.5);
    > div {
      width: 50%;
    }
    &--left {
      align-content: center;
      padding: 50px;
    }
    &__title {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &__media {
      align-content: center;
      video {
        width: 100%;
        height: auto;
      }
    }
  }
  &__item:nth-child(odd) {
    // background-color: $color-black-2;
  }
  &__item:nth-last-child {
    // background-color: $color-black-1;
    border-bottom: none;
  }
}
