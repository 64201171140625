@import "#styles/mixins.scss";
@import "#styles/variables.scss";

.privacy {
  background-color: #ebebeb;
  padding: 30px 10px 20px 10px;

  &__section {
    background-color: $color-white-1;
    border: 10px solid #e0e0e0;
    padding: 40px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 4px solid $color-red-1;

      &__title {
        font-size: 60px;
        color: $color-red-1;
      }

      &__date {
        font-size: 24px;
        color: black;
      }
    }

    &__content {
      margin-top: 46px;
      font-size: 24px;

      & div {
        color: black;
      }

      & ol {
        margin-left: calc(1em + 8px);
      }

      &__emphasize {
        color: $color-red-1;
        list-style-type: decimal;
      }

      &__text {
        margin-bottom: 16px;
      }

      &__odd_olist {
        counter-reset: item;
        & > li {
          color: black;
          list-style-type: decimal;
        }
      }

      &__even_olist {
        counter-reset: item;
        & > li {
          color: black;
          counter-increment: item;
          list-style-type: none;

          &::marker {
            content: "(" counter(item) ") ";
            margin-right: 1rem;
          }
        }
      }
    }

    &__en_content {
      margin-top: 46px;
      font-size: 24px;

      & div {
        color: black;
      }
      & span {
        color: black;
        margin-bottom: 16px;
        
      }

      &__olist {
        
        counter-reset: section;
        list-style-type: none;
        padding-left: calc(3.25em);

        &__li {
          position: relative;
          color: black;
        }

        &__li::before {
          position: absolute;
          left: calc(-3.25em);
          counter-increment: section;
          content: counters(section, ".") " ";
        }
      }

      &__text {
      }
    }
  }
}
.emphasize {
  color: $color-red-1;
}
