@import "./mixins.scss";
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  color: $color-white-1;
  font-family: "Noto Sans TC", sans-serif;
  line-height: 1.5;
  box-sizing: border-box;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  background-color: $color-black-2;

  &::-webkit-scrollbar {
    background-color: $color-black-1;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-red-1;
    border-radius: 5px;
  }
}

main {
  margin-top: $header-height;

  @include respond(l) {
    margin-top: $header-height-l;
  }

  @include respond(s) {
    margin-top: $header-height-s;
  }

  img,
  video {
    border-radius: $img-border-radius;
  }

  h2 {
    font-size: 48px;
    margin-bottom: 15px;

    @include respond(m) {
      font-size: 40px;
    }

    @include respond(s) {
      font-size: 36px;
      margin-bottom: 10px;
    }

    & ~ p {
      font-size: 20px;

      @include respond(m) {
        font-size: 18px;
      }

      @include respond(s) {
        font-size: 16px;
      }
    }
  }

  section {
    padding: $section-vertical-padding calc((100vw - $max-width) / 2);

    @include respond(xl) {
      padding-left: $section-padding-xl;
      padding-right: $section-padding-xl;
    }

    @include respond(m) {
      padding-left: $section-padding-m;
      padding-right: $section-padding-m;
    }

    @include respond(s) {
      padding: $section-vertical-padding-s $section-padding-s;
    }

    &.fullHeight {
      height: calc(100vh - $header-height);
      padding-top: 30px;
      padding-bottom: 70px;

      @include respond(l) {
        height: auto;
        padding-top: $section-vertical-padding;
        padding-bottom: $section-vertical-padding;
      }

      @include respond(s) {
        padding-top: $section-vertical-padding-s;
        padding-bottom: $section-vertical-padding-s;
      }
    }

    &:nth-of-type(even) {
      background-color: $color-black-1;
    }
  }

  table {
    border-spacing: 0;

    tbody tr {
      &:nth-child(odd) {
        background-color: rgba($color-black-1, 0.65);
      }

      &:nth-child(even) {
        background-color: rgba($color-black-2, 0.65);
      }
    }

    td {
      padding: 10px 20px;

      @include respond(s) {
        padding: 10px 15px;
      }
    }
  }
}
