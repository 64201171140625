@import "#styles/mixins.scss";
@import "#styles/variables.scss";

$game-card-size: 250px;
$game-card-size-l: 200px;
$game-card-gap: 50px;
$game-card-gap-l: 24px;

.games {
  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--leftRight {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;

      @include respond(l) {
        flex-direction: column-reverse;
        gap: 30px;
      }

      > * {
        width: 40%;

        @include respond(l) {
          width: 100%;
        }
      }
    }
  }

  &__text {
    list-style-type: disc !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    max-width: 500px;
    min-width: 500px;

    @include respond(l) {
      min-width: unset;
    }
  }

  &__title {
    margin-bottom: 30px;

    @include respond(s) {
      margin-bottom: 20px;
    }
  }

  &__title,
  &__releaseDate {
    position: relative;
    line-height: 1;
  }

  &__button {
    margin-top: 30px !important;

    @include respond(s) {
      margin-top: 20px !important;
    }

    > a {
      display: flex;
      width: 100%;
      padding: 15px 40px;
      justify-content: center;
    }
  }

  &__tabs {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }

  &__list {
    display: flex;
    min-height: $game-card-size;
    justify-content: center;
    flex-wrap: wrap;
    gap: $game-card-gap;

    @include respond(l) {
      min-height: $game-card-size-l;
      gap: $game-card-gap-l;
    }

    &__item {
      display: flex;
      transition: all 0.2s;
      position: relative;

      &.coming,
      &.new,
      &.hot {
        position: relative;

        &::after {
          position: absolute;
          padding: 7px 20px;
          font-size: 14px;
          border-top-left-radius: $img-border-radius;
          border-bottom-right-radius: $img-border-radius;
        }
      }

      &.coming::after {
        content: "SOON";
        background-color: $color-black-2;
      }

      &.hot::after {
        content: "HOT";
        background-color: $color-red-1;
      }

      &.new::after {
        content: "NEW";
        background-color: $color-blue-1;
      }

      &:hover {
        transform: scale(1.05);
      }

      img {
        width: $game-card-size;
        height: $game-card-size;
        box-shadow: 7px 9px 10px #050505;
        transition: all 0.2s;

        @include respond(l) {
          width: $game-card-size-l;
          height: $game-card-size-l;
        }

        &:hover {
          box-shadow: 4px 6px 10px #050505;
        }
      }
    }
  }
}
