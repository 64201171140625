@import "#styles/mixins.scss";
@import "#styles/variables.scss";

.homepage {
  &__section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__texts {
    position: absolute;
    top: 50px;
    left: 50%;
    width: 90%;
    text-align: center;
    transform: translateX(-50%);
    text-shadow: 
    /* first layer at 1px */ -1px -1px $color-black-1,
      0px -1px $color-black-1, 1px -1px $color-black-1, -1px 0px $color-black-1,
      1px 0px $color-black-1, -1px 1px $color-black-1, 0px 1px $color-black-1,
      1px 1px $color-black-1, /* second layer at 2px */ -2px -2px $color-black-1,
      -1px -2px $color-black-1, 0px -2px $color-black-1, 1px -2px $color-black-1,
      2px -2px $color-black-1, 2px -1px $color-black-1, 2px 0px $color-black-1,
      2px 1px $color-black-1, 2px 2px $color-black-1, 1px 2px $color-black-1,
      0px 2px $color-black-1, -1px 2px $color-black-1, -2px 2px $color-black-1,
      -2px 1px $color-black-1, -2px 0px $color-black-1, -2px -1px $color-black-1;

    @include respond(xl) {
      top: 70px;
    }

    @media (max-width: 580px) {
      position: static;
      margin-top: 20px;
      align-self: center;
      text-shadow: none;
      transform: none;
    }

    > p {
      font-size: 48px;

      @include respond(xl) {
        font-size: 38px;
      }

      @include respond(l) {
        font-size: 30px;
      }

      @include respond(m) {
        font-size: 24px;
      }

      > small {
        font-size: 0.8em;
      }
    }
  }
}
