@import "#styles/mixins.scss";
@import "#styles/variables.scss";

$newsCard-maxWidth: 1200px;
$newsCard-img-width: 280px;
$newsCard-img-width-l: 240px;
$newsCard-img-height: 180px;

.newsCard {
  position: relative;
  background: linear-gradient(90deg, $color-black-2, $color-black-1);
  display: flex;
  max-width: $newsCard-maxWidth;
  height: $newsCard-img-height;

  @include respond(m) {
    background: linear-gradient(180deg, $color-black-2, $color-black-1);
    height: auto;
    flex-direction: column;
  }

  &:hover {
    opacity: 0.85;
  }

  > a {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__type {
    background-color: $color-red-1;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    padding: 5px 20px;
    border-bottom-right-radius: 10px;
  }

  &__img {
    background-color: #555;
    width: $newsCard-img-width;
    height: $newsCard-img-height;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 0;
      object-fit: cover;
    }

    @include respond(l) {
      width: $newsCard-img-width-l;
    }

    @include respond(m) {
      width: 100%;
      height: 200px;
    }
  }

  &__text {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    @include respond(m) {
      padding: 10px 15px;
    }

    &__title {
      display: -webkit-box;
      font-size: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include respond(m) {
        font-size: 24px;
        -webkit-line-clamp: 2;
      }
    }
  }
}
