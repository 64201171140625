@import "#styles/mixins.scss";
@import "#styles/variables.scss";
@import "#styles/extends.scss";

.newsDetail {
  &__back {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    column-gap: 5px;
    opacity: 0.8;
    transition: all 0.2s;

    &:hover {
      column-gap: 8px;
      opacity: 1;
    }

    &__icon {
      font-size: 2em !important;

      > path {
        color: $color-red-1;
      }
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond(m) {
      row-gap: 10px;
    }
  }

  &__banner {
    display: flex;
    height: 60vw;
    max-height: 600px;
    justify-content: center;

    @include respond(l) {
      height: auto;
    }

    > video,
    img {
      max-width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 32px;
  }

  &__date {
    margin-bottom: 20px;
    opacity: 0.55;
  }

  &__content {
    opacity: 0.75;

    &__markdown {
      @extend %markdown;
    }

    > p:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
