%markdown {
  ol {
    list-style: inherit;

    list-style-position: outside;
    > li {
      list-style-type: decimal;
      margin-left: 1em;
      padding-left: 1em;
    }
  }
  ul {
    list-style: initial;

    list-style-position: outside;
    > li {
      list-style-type: disc;
      margin-left: 1em;
      padding-left: 1em;

      ul {
        > li {
          list-style-type: square;
          margin-left: 1em;
          padding-left: 1em;
        }
      }
    }
  }
}
