@import "#styles/mixins.scss";
@import "#styles/variables.scss";

.backOffice {
  &__section {
    display: flex;

    &--leftRight {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include respond(l) {
        flex-direction: column;
        row-gap: 20px;
      }

      @include respond(s) {
        flex-direction: column;
        row-gap: 10px;
      }

      > * {
        width: 49%;

        @include respond(l) {
          width: 100%;
        }
      }
    }
  }

  &__image {
    border-radius: 10px;

    > img,
    video {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }

    @include respond(l) {
      order: 1;
    }

    @include respond(m) {
      height: 350px;
    }

    @include respond(s) {
      height: 200px;
    }
  }

  &__text {
    @include respond(l) {
      order: 2;
    }
  }
}
