@import "#styles/variables.scss";

.toTop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  border: 2.5px solid $color-white-1;
  border-radius: 50%;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.15s, opacity 0.15s;
  backdrop-filter: blur(7px);

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
