@import "#styles/mixins.scss";
@import "#styles/variables.scss";

$timeline-padding: 5px 10px;
$timeline-border: 1.5px solid $color-white-1;
$timeline-border-radius: 5px;

.history {
  &__section {
    display: flex;
    flex-direction: column;
  }

  &__title,
  &__content {
    align-self: center;
  }

  &__timeline {
    margin-top: 20px;

    @include respond(m) {
      display: flex;
      justify-content: center;
    }

    &__content {
      display: inline-flex;
      background-color: $color-black-2;
      max-width: 500px;
      width: 100%;
      border: $timeline-border;
      border-radius: $timeline-border-radius;
      flex-direction: column;
      text-align: left;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.02);
      }
    }

    &__header {
      display: flex;
      padding: $timeline-padding;
      border-bottom: $timeline-border;
      justify-content: space-between;
      align-items: center;

      @include respond(m) {
        width: 80vw;
        max-width: 100%;
      }
    }

    &__title {
      font-size: 18px;
    }

    &__image {
      height: 230px;
      overflow: hidden;

      @include respond(m) {
        width: 80vw;
        max-width: 100%;
      }

      > img {
        width: 100%;
        height: 100%;
        border-radius: 0;
        object-fit: contain;
      }
    }

    &__description {
      padding: $timeline-padding;
      font-size: 14px;
    }

    &__year {
      padding: 3px 30px;
      background-color: $color-white-1;
      color: $color-black-2;
      font-weight: bold;
      border: $timeline-border;
      border-radius: $timeline-border-radius;
    }
  }
}
